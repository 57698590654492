/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DesktopSubmenuPlaceholder_product = {
    readonly name: string;
    readonly code: string;
    readonly " $fragmentRefs": FragmentRefs<"ProductTheme_product">;
    readonly " $refType": "DesktopSubmenuPlaceholder_product";
};
export type DesktopSubmenuPlaceholder_product$data = DesktopSubmenuPlaceholder_product;
export type DesktopSubmenuPlaceholder_product$key = {
    readonly " $data"?: DesktopSubmenuPlaceholder_product$data;
    readonly " $fragmentRefs": FragmentRefs<"DesktopSubmenuPlaceholder_product">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "DesktopSubmenuPlaceholder_product",
  "type": "WebsiteMarketingProduct",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "code",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ProductTheme_product",
      "args": null
    }
  ]
};
(node as any).hash = 'f57bb4a08c0e7e60bca6cb851c7b1556';
export default node;
