// TODO: If you make edits to this file you must deal with these ESLint rules
/*eslint-disable @typescript-eslint/explicit-module-boundary-types */

import * as React from 'react';

import useLogout from '@zego/hooks/useLogout';

import MenuItem from '@zego/components/atoms/HeaderMenuItem';
import MenuRow from '@zego/components/MenuRow';

export default function LogoutCTA({ as = 'desktop' }): JSX.Element {
  const logout = useLogout();

  if (as === 'desktop') {
    return (
      <MenuItem
        onClick={async () => await logout('/')}
        css={{
          textDecoration: 'none',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          '.no-touch &:hover': {
            textDecoration: 'underline',
          },
        }}
      >
        Logout
      </MenuItem>
    );
  }

  return (
    <div onClick={async () => await logout('/')}>
      {/* @ts-expect-error: BC2-452 added this placeholder error description */}
      <MenuRow heading="Logout" />
    </div>
  );
}
