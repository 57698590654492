import React, { useContext } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import { rgba } from 'polished';

import PageContext from '@zego/context/PageContext';
import { disabledColor, unit } from '@zego/theme';
import { useTracking } from '@zego/tracking';
import {
  landingPagePathFromProductCode,
  pathWithCountry,
} from '@zego/utils/route.ts';

import RegularText from '@zego/components/atoms/RegularText';
import Spacer from '@zego/components/atoms/Spacer';
import View from '@zego/components/atoms/View';

const DesktopSubmenuProduct = ({ product }) => {
  const { country } = useContext(PageContext);
  const { trackEvent } = useTracking();

  return (
    <li
      css={{
        margin: -unit(2),
        whiteSpace: 'normal',
      }}
      onClick={() => {
        trackEvent(`${product.name} - TopNav`);
      }}
    >
      <a
        href={pathWithCountry(
          country,
          landingPagePathFromProductCode(product.code),
        )}
        css={{
          display: 'block',
          textDecoration: 'none',
          borderRadius: 6,
          padding: unit(2),
          height: '100%',
          ':hover, :focus': {
            backgroundColor: rgba(0, 0, 0, 0.05),
          },
        }}
      >
        <View css={{ flexDirection: 'column' }}>
          <View css={{ flex: 1 }}>
            <RegularText
              css={{
                fontSize: 16,
                color: disabledColor(10),
                fontWeight: 500,
              }}
            >
              {product.name}
            </RegularText>
            <Spacer height={unit(1)} />
            <RegularText css={{ color: disabledColor(7), fontSize: 14 }}>
              {product.shortDescription}
            </RegularText>
          </View>
        </View>
      </a>
    </li>
  );
};

export default createFragmentContainer(DesktopSubmenuProduct, {
  product: graphql`
    fragment DesktopSubmenuProduct_product on WebsiteMarketingProduct {
      name
      code
      shortDescription
    }
  `,
});
