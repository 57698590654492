/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DesktopHeader_categories = ReadonlyArray<{
    readonly name: string;
    readonly products: ReadonlyArray<{
        readonly " $fragmentRefs": FragmentRefs<"DesktopSubmenu_products">;
    }>;
    readonly " $refType": "DesktopHeader_categories";
}>;
export type DesktopHeader_categories$data = DesktopHeader_categories;
export type DesktopHeader_categories$key = ReadonlyArray<{
    readonly " $data"?: DesktopHeader_categories$data;
    readonly " $fragmentRefs": FragmentRefs<"DesktopHeader_categories">;
}>;



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "DesktopHeader_categories",
  "type": "WebsiteMarketingProductCategory",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "products",
      "storageKey": null,
      "args": null,
      "concreteType": "WebsiteMarketingProduct",
      "plural": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "DesktopSubmenu_products",
          "args": null
        }
      ]
    }
  ]
};
(node as any).hash = 'ffa7de2d6eb18af5902812ed2edfcfdc';
export default node;
