import getConfig from 'next/config';

import { withLDProvider } from 'launchdarkly-react-client-sdk';

const { publicRuntimeConfig } = getConfig();

/**
 * Add to a root component to provide feature flags through Context API
 * Consume with `useFlags`
 */
const withFeatureFlags = withLDProvider({
  clientSideID: publicRuntimeConfig.LAUNCH_DARKLY_CLIENT_ID,
  options: {
    bootstrap: 'localStorage',
  },
});

export default withFeatureFlags;
