import styled from '@emotion/styled';

const ClipShape = styled('div')({
  '@supports (clip-path: polygon(25% 0%, 75% 0%, 100% 100%, 0% 100%))': {
    position: 'absolute',
    left: '-50%',
    right: '-50%',
    top: '50%',
    bottom: 0,
    pointerEvents: 'none',
    clipPath: 'polygon(25% 0%, 75% 0%, 100% 100%, 0% 100%)',
  },
});

export default ClipShape;
