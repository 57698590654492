import React from 'react';
import { fetchQuery } from 'react-relay';

import QueryLookupRenderer from 'relay-query-lookup-renderer';

import createRelayEnvironment from '@zego/lib/createRelayEnvironment';
import { isBrowser } from '@zego/utils';

const WithDataHOC = (ComposedComponent, options = {}) => {
  const WithData = pageProps => {
    if (!options.query) {
      return <ComposedComponent {...pageProps} />;
    }

    return (
      <QueryLookupRenderer
        lookup={isBrowser()}
        query={options.query}
        environment={pageProps.environment}
        variables={pageProps.queryVariables}
        render={({ props, ...rest }) => {
          if (props) {
            return <ComposedComponent {...pageProps} {...rest} {...props} />;
          }
          return null;
        }}
      />
    );
  };

  WithData.displayName = `WithData(${
    ComposedComponent.displayName || ComposedComponent.name
  })`;

  WithData.getInitialProps = async context => {
    const environment = createRelayEnvironment({
      request: context.req || null,
    });

    // Evaluate the composed component's getInitialProps()
    let composedInitialProps = {};
    if (ComposedComponent.getInitialProps) {
      composedInitialProps = await ComposedComponent.getInitialProps(context);
    }

    let queryProps = {};
    let queryRecords = {};
    let variables = {};
    let pageHeaders;

    const pageQuery = {
      ...context.query,
      ...(context?.req?.params || {}),
      ...(context?.req?.query || {}),
    };

    if (options.preQuery) {
      const preQueryResult = await options.preQuery(
        {
          ...context,
          query: pageQuery,
        },
        environment,
      );
      if (preQueryResult) {
        pageHeaders = preQueryResult;
      }
    }

    if (options.query) {
      variables = options.getVariables
        ? options.getVariables({
            ...context,
            query: pageQuery,
          })
        : {};
      queryProps = await fetchQuery(environment, options.query, variables);

      queryRecords = environment.getStore().getSource().toJSON();
    }

    // Don't return queryProps because it adds significant weight to the Next payload
    // And all the important data is held by queryRecords
    const props = {
      ...composedInitialProps,
      query: pageQuery,
      queryVariables: variables,
      queryRecords,
    };
    if (options.getHeaders) {
      // We DO send queryProps to getHeaders, because it needs access to the query data
      const headers = options.getHeaders(
        { ...props, ...queryProps, query: props.query },
        context,
      );
      if (headers) {
        // Override headers
        pageHeaders = headers;
      }
    }

    return { ...props, pageHeaders };
  };

  return WithData;
};

export default WithDataHOC;
