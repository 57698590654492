import { useEffect, useRef, useState } from 'react';

import observeRect from '@reach/observe-rect';

export default function useRect(nodeRef, observe = true) {
  let [rect, setRect] = useState(null);
  let observerRef = useRef(null);
  useEffect(() => {
    if (!observerRef.current) {
      observerRef.current = observeRect(nodeRef.current, setRect);
    }
    if (observe) {
      observerRef.current.observe();
    }
    return () => observerRef.current.unobserve();
  }, [nodeRef, observe]);
  return rect;
}
