// TODO: If you make edits to this file you must deal with these ESLint rules
/*eslint-disable @typescript-eslint/no-use-before-define */

import React, { useContext } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import styled from '@emotion/styled';
import { Trans } from '@lingui/macro';
import getConfig from 'next/config';

import { useFlags } from 'launchdarkly-react-client-sdk';

import PageContext from '@zego/context/PageContext';
import { disabledColor, primaryColor, unit, white } from '@zego/theme';
import useTracking from '@zego/tracking/useTracking';
import { getCountrySupportLink, pathWithCountry } from '@zego/utils/route.ts';

import ChevronDown from '@zego/components/atoms/ChevronDown';
import ClipShape from '@zego/components/atoms/ClipShape';
import MenuItem from '@zego/components/atoms/HeaderMenuItem';
import MenuLink from '@zego/components/atoms/MenuLink';
import ContentContainer from '@zego/components/ContentContainer';
import LogoutCTA from '@zego/components/molecules/LogoutCTA';
import DesktopSubmenu from '@zego/components/organisms/DesktopSubmenu';
import withFeatureFlags from '@zego/components/withFeatureFlags';

import Logo from './Logo';

const MenuListItem = styled('li')({
  ':hover': {
    [ChevronDown]: {
      transform: 'rotate(180deg)',
    },
    [ClipShape]: {
      pointerEvents: 'auto',
      zIndex: 1,
    },
    [DesktopSubmenu]: {
      pointerEvents: 'auto',
      opacity: 1,
      zIndex: -1,
    },
  },
});

const Backdrop = styled('div')({
  position: 'absolute',
  height: 100,
  backgroundColor: white,
  pointerEvents: 'none',
  left: 0,
  right: 0,
  top: 0,
  transition: 'all 200ms ease-in-out',
  zIndex: -1,
  opacity: 0,
  boxShadow: 'none',
  transform: 'translateY(-10%)',
});

const HeaderWrapper = styled('div')(
  {
    height: 100,
    '.no-touch &:hover, .headroom--pinned &': {
      [Backdrop]: {
        opacity: 1,
        transform: 'translateY(0)',
        boxShadow: '0 0.5px 0 0 rgba(0, 0, 0, 0.15)',
      },
    },
  },
  ({ isInitiallyLightOnDark }) =>
    isInitiallyLightOnDark
      ? {
          '.headroom--unfixed &': {
            [Logo]: {
              fill: white,
            },
            [`${MenuItem}, ${MenuLink}`]: {
              color: white,
            },
            [ChevronDown]: {
              color: white,
            },
            '.no-touch &:hover': {
              [Logo]: {
                fill: primaryColor(11),
              },
              [`${MenuItem}, ${MenuLink}`]: {
                color: disabledColor(10),
              },
              [ChevronDown]: {
                color: disabledColor(10),
              },
            },
          },
        }
      : {},
);

const DesktopHeader = ({
  isInitiallyLightOnDark = true,
  showLogoutCTA = false,
  categories = [],
  container: HeaderContainer = ContentContainer,
}) => {
  const { country } = useContext(PageContext);

  const {
    publicRuntimeConfig: { FLEET_PORTAL_URL },
  } = getConfig();

  const { myAccountEnabled, myZegoAccountLoginPath } = useFlags();

  return (
    <HeaderWrapper
      isInitiallyLightOnDark={isInitiallyLightOnDark}
      role="navigation"
    >
      <HeaderContainer
        as="nav"
        css={{
          height: 100,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <a
          href={pathWithCountry(country, '/')}
          css={{
            flexGrow: 0,
            display: 'flex',
            paddingTop: unit(5),
            paddingBottom: unit(5),
            paddingRight: unit(3),
          }}
        >
          <Logo width={91} css={{ fill: primaryColor(11) }} />
        </a>
        <ul
          css={{
            flex: 1,
            listStyle: 'none',
            margin: 0,
            padding: 0,
            alignItems: 'center',
            display: 'flex',
          }}
        >
          {categories.map((category, i) => (
            <MenuListItem key={i}>
              <MenuItem>
                <Trans id="Website Header / Product Category Menu Item">
                  <span
                    css={{
                      display: 'none',
                      '@media screen and (min-width: 768px)': {
                        display: 'inline',
                      },
                    }}
                  >
                    For {category.name}
                  </span>
                </Trans>
                <div
                  css={{
                    display: 'inline-block',
                    marginLeft: unit(1),
                  }}
                >
                  <ChevronDown
                    css={{
                      color: disabledColor(10),
                      transform: 'rotate(0deg)',
                    }}
                  />
                </div>
                <ClipShape />
              </MenuItem>

              <DesktopSubmenu products={category.products} />
            </MenuListItem>
          ))}
          {country === 'gb' && (
            <MenuListItem>
              <MenuLink href="/broker/">Brokers</MenuLink>
            </MenuListItem>
          )}
          <MenuListItem>
            <MenuLink href={getCountrySupportLink(country)}>
              <Trans id="Website Header / Contact us">Support</Trans>
            </MenuLink>
          </MenuListItem>
          {country === 'gb' || country === 'ie' ? (
            <AuthButton
              enabled={myAccountEnabled}
              myZegoEnabled={myZegoAccountLoginPath}
              showLogoutCTA={showLogoutCTA}
            />
          ) : null}
        </ul>
        {country === 'es' && (
          <MenuListItem>
            <MenuLink href={FLEET_PORTAL_URL}>Accede al portal</MenuLink>
          </MenuListItem>
        )}
      </HeaderContainer>
      <Backdrop />
    </HeaderWrapper>
  );
};

const AuthButton = ({ enabled, myZegoEnabled, showLogoutCTA }) => {
  const { trackEvent } = useTracking();
  const accountUrl = myZegoEnabled ? '/my-zego/' : '/my-account/';
  if (!enabled) {
    return null;
  }
  return showLogoutCTA ? (
    <LogoutCTA />
  ) : (
    <MenuLink
      onClick={() => trackEvent('Website: Banner - My Account Clicked')}
      href={accountUrl}
    >
      My account
    </MenuLink>
  );
};

/**
 * Data export
 */
export default createFragmentContainer(withFeatureFlags(DesktopHeader), {
  categories: graphql`
    fragment DesktopHeader_categories on WebsiteMarketingProductCategory
      @relay(plural: true) {
      name
      products {
        ...DesktopSubmenu_products
      }
    }
  `,
});
