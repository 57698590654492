import React from 'react';

const fadeBetween = 'fill 0.2s linear';

/**
 * Cross icon with circle background
 *
 * @param {Object} props
 * @param {string} props.iconColor Cross icon colour
 * @param {string} props.background Background colour of the circle
 * @param {object} props.focus Optional object containing hover and focus styles
 * @param {string} props.focus.iconColor Optional hover/focus colour
 * @param {string} props.focus.backgroundColor Optional hover/focus colour
 */
export default function CrossIcon({ iconColor, background, focus }) {
  return (
    <svg
      height="18"
      viewBox="0 0 18 18"
      width="18"
      css={{
        display: 'block',
        verticalAlign: 'bottom',
        ':hover, :focus': {
          rect: { fill: focus?.background },
          path: { fill: focus?.iconColor },
        },
      }}
    >
      <g fill="none" fillRule="evenodd">
        <rect
          fill={background}
          height="18"
          rx="9"
          width="18"
          css={{ transition: fadeBetween }}
        />
        <path
          d="m9.03076923 7.88125574 1.67983677-1.67983676c.2685586-.26855864.7039859-.26855864.9725445 0l.1770067.17700666c.2685146.26857193.2685146.70397012-.0000377.97252247l-.486447.48644702.0035862.00608052-1.1921348 1.19213479 1.1921348 1.19213476-.0035862.0060805.486447.4864471c.2685523.2685523.2685523.7039505.0000377.9725224l-.1770067.1770067c-.2685586.2685586-.7039859.2685586-.9725445 0l-1.67983677-1.6798368-1.67983676 1.6798368c-.26855863.2685586-.70398592.2685586-.97254455 0l-.17700666-.1770067c-.26851463-.2685719-.26851463-.7039701.00003772-.9725224l.48658243-.4865825-.00372166-.0059451 1.19213478-1.19213476-1.19213478-1.19213479.00372166-.0059451-.48658243-.48658244c-.26855235-.26855235-.26855235-.70395054-.00003772-.97252247l.17700666-.17700666c.26855863-.26855864.70398592-.26855864.97254455 0z"
          fill={iconColor}
          fillRule="nonzero"
          css={{ transition: fadeBetween }}
        />
      </g>
    </svg>
  );
}
