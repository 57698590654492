/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DesktopSubmenuProduct_product = {
    readonly name: string;
    readonly code: string;
    readonly shortDescription: string | null;
    readonly " $refType": "DesktopSubmenuProduct_product";
};
export type DesktopSubmenuProduct_product$data = DesktopSubmenuProduct_product;
export type DesktopSubmenuProduct_product$key = {
    readonly " $data"?: DesktopSubmenuProduct_product$data;
    readonly " $fragmentRefs": FragmentRefs<"DesktopSubmenuProduct_product">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "DesktopSubmenuProduct_product",
  "type": "WebsiteMarketingProduct",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "code",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "shortDescription",
      "args": null,
      "storageKey": null
    }
  ]
};
(node as any).hash = 'c06b58a6beb07a688ee28fa6074719f4';
export default node;
