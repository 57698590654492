import React, { useCallback, useEffect, useRef } from 'react';
import Modal from 'react-modal';

import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from 'body-scroll-lock';

const options = { reserveScrollBarGap: true };

Modal.setAppElement('#__next');

export default function useModal(isOpen) {
  const modalRef = useRef();
  const previouslyOpen = useRef(isOpen);

  useEffect(() => {
    previouslyOpen.current = isOpen;

    if (previouslyOpen.current && !isOpen) {
      enableBodyScroll(modalRef.current.node.lastElementChild, options);
    }

    previouslyOpen.current = isOpen;
    return clearAllBodyScrollLocks;
  }, [isOpen]);

  const _handleOpened = useCallback(() => {
    if (isOpen) {
      disableBodyScroll(modalRef.current.node.lastElementChild, options);
    }
  }, [isOpen]);

  const WrappedModal = useCallback(
    props => (
      <Modal
        isOpen={isOpen}
        ref={modalRef}
        onAfterOpen={_handleOpened}
        {...props}
      />
    ),
    [_handleOpened, isOpen],
  );

  return [WrappedModal];
}
