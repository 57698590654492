import React from 'react';

import { ClassNames } from '@emotion/core';

import useModal from '@zego/hooks/useModal';
import { unit, white } from '@zego/theme';

import UnstyledButton from '@zego/components/button/UnstyledButton';
import CloseIcon from '@zego/components/CloseIcon.svg';

export default function MobileModal({
  children,
  contentLabel,
  isOpen,
  onRequestClose,
}) {
  const [Modal] = useModal(isOpen);

  return (
    <ClassNames>
      {({ css }) => (
        <Modal
          contentLabel={contentLabel}
          closeTimeoutMS={450}
          overlayClassName={css({
            zIndex: 999999,
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: white,
            opacity: 0,
            transform: 'translateY(-100vh)',
            '&.ReactModal__Overlay--after-open': {
              transition: 'all 200ms ease-in-out',
              opacity: 1,
              transform: 'translateY(0)',
            },
            '&.ReactModal__Overlay--before-close': {
              opacity: 0,
              transform: 'translateY(-100vh)',
            },
          })}
          css={{
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 0,
            top: 0,
            opacity: 0,
            transition: 'all 300ms ease-in-out 100ms',
            outline: 'none',
            WebkitTapHighlightColor: 'rgba(0,0,0,0)',
            '&.ReactModal__Content--after-open': {
              opacity: 1,
            },
            '&.ReactModal__Content--before-close': {
              transition: 'all 50ms ease-in-out',
              opacity: 0,
            },
          }}
        >
          <div
            css={{
              position: 'absolute',
              left: 0,
              right: 0,
              bottom: 0,
              top: 0,
              overflowY: 'auto',
            }}
          >
            <UnstyledButton
              css={{
                position: 'absolute',
                top: 0,
                right: 0,
                padding: `0 ${unit(4)}px`,
                height: 100,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 1,
              }}
              onClick={onRequestClose}
              aria-label="Close"
            >
              <img
                alt=""
                src={CloseIcon}
                css={{ width: 14, height: 14, display: 'block' }}
              />
            </UnstyledButton>
            {children}
          </div>
        </Modal>
      )}
    </ClassNames>
  );
}
