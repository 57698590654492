import styled from '@emotion/styled';

import MenuItem from '@zego/components/atoms/HeaderMenuItem';
import Link from '@zego/components/Link';

const MenuLink = styled(MenuItem)({
  textDecoration: 'none',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  '.no-touch &:hover': {
    textDecoration: 'underline',
  },
}).withComponent(Link);

export default MenuLink;
