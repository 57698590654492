import React, { useState } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import OutsideClickHandler from 'react-outside-click-handler';
import { Manager, Popper, Reference } from 'react-popper';

import { disabledColor, white } from '@zego/theme';
import { pathWithCountry } from '@zego/utils/route.ts';

import UnstyledButton from '@zego/components/button/UnstyledButton';

import chevronDown from '@zego/static/images/icons/chevron-down.svg';
import Tick from '@zego/static/svgs/Tick.svg';

const FooterCountryPicker = ({ currentCountry, viewer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const selectedCountry = viewer.countries.find(
    country => country.countryAlpha2 === currentCountry.toUpperCase(),
  );

  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <UnstyledButton
            ref={ref}
            onClick={() => setIsOpen(true)}
            css={{
              fontSize: 14,
              display: 'inline-block',
              position: 'relative',
              paddingRight: 20,
              cursor: 'pointer',
              ':hover': {
                opacity: 0.5,
              },
              ':after': {
                display: 'block',
                position: 'absolute',
                content: '""',
                backgroundImage: `url(${chevronDown})`,
                width: 16,
                height: 16,
                top: '50%',
                right: 0,
                marginTop: -9,
              },
            }}
          >
            {selectedCountry.displayName}
          </UnstyledButton>
        )}
      </Reference>
      {isOpen && (
        <Popper
          placement="top"
          modifiers={{
            offset: { offset: '0, 20' },
            preventOverflow: { padding: 30 },
          }}
        >
          {({ ref, style, placement, arrowProps }) => (
            <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
              <div
                ref={ref}
                css={{
                  minWidth: 250,
                  backgroundColor: white,
                  borderRadius: 12,
                  boxShadow: `0 24px 30px -20px rgba(42,42,42,0.2), 0 0 2px 0px rgba(42,42,42,0.2)`,
                  transformOrigin: 'center bottom',
                  padding: 10,
                }}
                style={style}
                data-placement={placement}
              >
                {viewer.countries.map(country => (
                  <a
                    href={pathWithCountry(
                      country.countryAlpha2.toLowerCase(),
                      '/',
                    )}
                    css={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      padding: '16px 16px',
                      lineHeight: 1,
                      textDecoration: 'none',
                      color: disabledColor(7),
                      borderRadius: 4,
                      ':hover': {
                        backgroundColor: 'rgba(0, 0, 0, .05)',
                      },
                      pointerEvents:
                        country.countryAlpha2 === selectedCountry.countryAlpha2
                          ? 'none'
                          : 'auto',
                    }}
                    key={country.id}
                  >
                    {country.displayName}
                    {country.countryAlpha2 ===
                    selectedCountry.countryAlpha2 ? (
                      <img
                        alt="Selected"
                        src={Tick}
                        css={{ width: 16, height: 16, display: 'block' }}
                      />
                    ) : null}
                  </a>
                ))}
                <div
                  css={{
                    position: 'absolute',
                    bottom: -10,
                    borderLeft: '10px solid transparent',
                    borderRight: '10px solid transparent',
                    borderTop: `10px solid ${white}`,
                  }}
                  ref={arrowProps.ref}
                  data-placement={placement}
                  style={arrowProps.style}
                />
              </div>
            </OutsideClickHandler>
          )}
        </Popper>
      )}
    </Manager>
  );
};

export default createFragmentContainer(FooterCountryPicker, {
  viewer: graphql`
    fragment FooterCountryPicker_viewer on Viewer {
      countries: visibleCountriesByPlatform(platform: WEBSITE) {
        id
        countryAlpha2
        displayName
      }
    }
  `,
});
