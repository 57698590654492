/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type FooterCountryPicker_viewer = {
    readonly countries: ReadonlyArray<{
        readonly id: string;
        readonly countryAlpha2: string;
        readonly displayName: string;
    }>;
    readonly " $refType": "FooterCountryPicker_viewer";
};
export type FooterCountryPicker_viewer$data = FooterCountryPicker_viewer;
export type FooterCountryPicker_viewer$key = {
    readonly " $data"?: FooterCountryPicker_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"FooterCountryPicker_viewer">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "FooterCountryPicker_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": "countries",
      "name": "visibleCountriesByPlatform",
      "storageKey": "visibleCountriesByPlatform(platform:\"WEBSITE\")",
      "args": [
        {
          "kind": "Literal",
          "name": "platform",
          "value": "WEBSITE"
        }
      ],
      "concreteType": "Country",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "countryAlpha2",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "displayName",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
(node as any).hash = 'e4d344f87b5412160edeef2b2c527d67';
export default node;
