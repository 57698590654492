import React from 'react';

import { unit } from '@zego/theme';

import ContentContainer from '@zego/components/ContentContainer';
import MenuSections from '@zego/components/MenuSections';

export default function MobileSubmenu({
  sections,
  isMobileHeader,
  showLogoutCTA,
}) {
  return (
    <ContentContainer
      css={{
        paddingTop: unit(10),
        paddingBottom: unit(10),
      }}
      aria-label="Main menu"
    >
      <MenuSections
        as="mobile"
        sections={sections}
        isMobileHeader={isMobileHeader}
        showLogoutCTA={showLogoutCTA}
      />
    </ContentContainer>
  );
}
