import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import { BREAKPOINTS } from '@zego/theme';

import Footer from '@zego/components/footer';
import Header from '@zego/components/header';

import SessionLayout from './SessionLayout';

/**
 * @param {Object} props
 * @param {React.ReactNode} [props.children]
 * @param {boolean} [props.headerIsInitiallyLightOnDark]
 * @param {React.ComponentType} [props.headerContainer]
 * @param {boolean} [props.hideFooter]
 * @param {boolean} [props.hideHeader]
 * @param {React.ReactNode} [props.header]
 * @param {boolean} [props.showLogoutCTA]
 * @param {boolean} [props.unshiftHeader]
 * @param {boolean} [props.upshiftContent]
 * @param {import('@zego-types/BaseLayout_viewer.graphql').BaseLayout_viewer} props.viewer
 * @param {React.ReactNode} [props.banner]
 *
 * @param {string} [props.title]
 * @param {string} [props.description]
 * @param {string} [props.ogImage]
 * @param {string | number} [props.ogImageWidth]
 * @param {string | number} [props.ogImageHeight]
 * @param {unknown} [props.structuredData] - TODO what is this?
 */
const BaseLayout = ({
  children,
  headerIsInitiallyLightOnDark,
  headerContainer,
  hideFooter,
  hideHeader,
  header,
  showLogoutCTA,
  unshiftHeader = true,
  upshiftContent = false,
  viewer,
  banner,
  ...props
}) => {
  return (
    <SessionLayout {...props} viewer={viewer}>
      <div css={{ overflow: 'hidden' }}>
        {banner}
        {header ? (
          header
        ) : hideHeader ? null : (
          <Header
            showLogoutCTA={showLogoutCTA}
            container={headerContainer}
            isInitiallyLightOnDark={headerIsInitiallyLightOnDark}
            viewer={viewer}
          />
        )}
        <main
          css={{
            paddingTop: unshiftHeader ? 0 : 100,
            ...(upshiftContent
              ? {
                  [BREAKPOINTS.normal]: {
                    position: 'relative',
                    top: '-100px',
                  },
                }
              : undefined),
          }}
        >
          {children}
        </main>
        {!hideFooter ? <Footer viewer={viewer} /> : null}
      </div>
    </SessionLayout>
  );
};

export default createFragmentContainer(BaseLayout, {
  viewer: graphql`
    fragment BaseLayout_viewer on Viewer
      @argumentDefinitions(country: { type: "String!" }) {
      ...SessionLayout_viewer
      ...Header_viewer @arguments(country: $country)
      ...Footer_viewer @arguments(country: $country)
    }
  `,
});
