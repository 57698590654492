import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import { css } from '@emotion/core';
import styled from '@emotion/styled';

import { getColWidthCalc, unit, white } from '@zego/theme';

import ContentContainer from '@zego/components/ContentContainer';
import DesktopSubmenuPlaceholder from '@zego/components/header/DesktopSubmenuPlaceholder';
import DesktopSubmenuProduct from '@zego/components/header/DesktopSubmenuProduct';

function DesktopSubmenu({ products = [], className }) {
  return (
    <div className={className}>
      <ContentContainer css={{ paddingLeft: 0, paddingRight: 0 }}>
        <ul
          css={css`
            width: ${getColWidthCalc(12, 20, 10)};
            margin: 0 auto;
            display: grid;
            grid-column-gap: ${unit(5)}px;
            grid-row-gap: ${unit(5)}px;
            grid-auto-rows: auto;
            grid-template-columns: 1fr 1fr 1fr;
          `}
        >
          {products.map(product =>
            product.isComingSoon ? (
              <DesktopSubmenuPlaceholder
                key={product.name}
                product={product}
              />
            ) : (
              <DesktopSubmenuProduct key={product.name} product={product} />
            ),
          )}
        </ul>
      </ContentContainer>
    </div>
  );
}

export default styled(
  createFragmentContainer(DesktopSubmenu, {
    products: graphql`
      fragment DesktopSubmenu_products on WebsiteMarketingProduct
        @relay(plural: true) {
        name
        isComingSoon
        ...DesktopSubmenuProduct_product
        ...DesktopSubmenuPlaceholder_product
      }
    `,
  }),
)({
  position: 'absolute',
  pointerEvents: 'none',
  backgroundColor: white,
  width: '100vw',
  left: 0,
  top: 0,
  paddingTop: `calc(100px + ${unit(8)}px)`,
  paddingBottom: unit(8),
  opacity: 0,
  zIndex: -2,
  boxShadow: '0 0 64px 0 rgba(0, 0, 0, 0.1)',
});
