import styled from '@emotion/styled';

import { disabledColor, unit } from '@zego/theme';

const MenuItem = styled('div')({
  position: 'relative',
  display: 'block',
  textDecoration: 'none',
  transition: 'color 200ms ease-in-out, opacity 50ms ease-in-out',
  paddingLeft: unit(1),
  paddingRight: unit(1),
  height: 100,
  fontWeight: 500,
  fontSize: 16,
  WebkitFontSmoothing: 'antialiased',
  color: disabledColor(10),
  paddingTop: unit(5),
  paddingBottom: unit(5),
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  cursor: 'pointer',
  '@media screen and (min-width: 1024px)': {
    paddingLeft: unit(3),
    paddingRight: unit(3),
  },
});

export default MenuItem;
