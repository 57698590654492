import React from 'react';

import { unit } from '@zego/theme';

import SectionHeader from './SectionHeader';

export default function FooterSection({ heading, children }) {
  return (
    <div
      css={{
        flex: 1,
        padding: unit(1),
      }}
    >
      <SectionHeader>{heading}</SectionHeader>
      <div
        css={{
          marginTop: -unit(1),
        }}
      >
        {children}
      </div>
    </div>
  );
}
