import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import { Trans } from '@lingui/macro';

import { disabledColor, errorColor, unit } from '@zego/theme';

import RegularText from '@zego/components/atoms/RegularText';
import Spacer from '@zego/components/atoms/Spacer';
import View from '@zego/components/atoms/View';
import ProductTheme from '@zego/components/ProductTheme';

const DesktopSubmenuPlaceholder = ({ product }) => (
  <ProductTheme product={product}>
    <li
      css={{
        margin: -unit(2),
      }}
    >
      <div
        css={{
          display: 'block',
          textDecoration: 'none',
          borderRadius: 6,
          padding: unit(2),
          height: '100%',
        }}
      >
        <View css={{ flexDirection: 'column' }}>
          <View css={{ flex: 1 }}>
            <RegularText
              css={{
                fontSize: 16,
                color: disabledColor(6),
                fontWeight: 500,
              }}
            >
              {product.name}
            </RegularText>
            <Spacer height={unit(1)} />
            <RegularText
              css={{
                color: errorColor(3),
                fontWeight: 'bold',
                fontSize: 12,
                textTransform: 'uppercase',
              }}
            >
              <Trans id="Website Header / Placeholder Product Label">
                Coming Soon
              </Trans>
            </RegularText>
          </View>
        </View>
      </div>
    </li>
  </ProductTheme>
);

export default createFragmentContainer(DesktopSubmenuPlaceholder, {
  product: graphql`
    fragment DesktopSubmenuPlaceholder_product on WebsiteMarketingProduct {
      ...ProductTheme_product
      name
      code
    }
  `,
});
