/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SessionLayout_viewer = {
    readonly session: {
        readonly id: string;
        readonly token: string;
        readonly expiresAt: string;
    };
    readonly currentUser: {
        readonly fullName: string;
        readonly customerNumber: string;
    } | null;
    readonly " $refType": "SessionLayout_viewer";
};
export type SessionLayout_viewer$data = SessionLayout_viewer;
export type SessionLayout_viewer$key = {
    readonly " $data"?: SessionLayout_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"SessionLayout_viewer">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "SessionLayout_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "session",
      "storageKey": null,
      "args": null,
      "concreteType": "Session",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "token",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "expiresAt",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "currentUser",
      "storageKey": null,
      "args": null,
      "concreteType": "User",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "fullName",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "customerNumber",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
(node as any).hash = '7d267cd09ea5fa8a2a919a9d78d01144';
export default node;
