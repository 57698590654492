// TODO: If you make edits to this file you must deal with these ESLint rules
/*eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { useEffect } from 'react';

import { SiftDataLayer } from './types/useSiftDataLayer.types';

export default function useSiftDataLayer(values: SiftDataLayer) {
  useEffect(() => {
    if (window._sift) {
      window.siftDataLayer = { ...window.siftDataLayer, ...values };

      return function cleanup() {
        window.siftDataLayer = undefined;
      };
    }
  }, [values]);
}
