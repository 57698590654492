import styled from '@emotion/styled';

import { disabledColor, unit } from '@zego/theme';

const SectionHeader = styled('h2')({
  color: disabledColor(10),
  fontSize: 14,
  fontWeight: 600,
  margin: 0,
  paddingBottom: unit(3),
});

export default SectionHeader;
