import React, { useContext, useState } from 'react';

import Cookies from 'js-cookie';

import PageContext from '@zego/context/PageContext';
import { disabledColor, errorColor, unit } from '@zego/theme';

import ContentContainer from '@zego/components/ContentContainer';
import CrossIcon from '@zego/components/CrossIcon';

const ENABLED_COUNTRIES = ['gb', 'ie', 'es', 'nl'];

const COUNTRY_CONFIGS = {
  gb: {
    heading: 'Are you in the United Kingdom?',
    linkText: 'Visit our United Kingdom website',
    link: '/',
  },
  ie: {
    heading: 'Are you in Ireland?',
    linkText: 'Visit our Irish website',
    link: '/ie/',
  },
  es: {
    heading: '¿Estas en España?',
    linkText: 'Visita nuestra web española',
    link: '/es/',
  },
  nl: {
    heading: 'Ben je in Nederland?',
    linkText: 'Bezoek onze Nederlandse website',
    link: '/nl/',
  },
};

const COOKIE_NAME = 'hasDismissedMismatchedCountryBanner';

const MismatchedCountryBanner = React.forwardRef((props, ref) => {
  const {
    country: pageCountry,
    detectedCountry,
    hasDismissedMismatchedCountryBanner,
  } = useContext(PageContext);
  const [isClosed, setIsClosed] = useState(false);

  const shouldShow =
    !isClosed &&
    detectedCountry &&
    !hasDismissedMismatchedCountryBanner &&
    ENABLED_COUNTRIES.includes(detectedCountry) &&
    detectedCountry !== pageCountry;

  const _hide = () => {
    setIsClosed(true);
    Cookies.set(COOKIE_NAME, true);
  };

  const _handleLinkClick = () => {
    Cookies.set(COOKIE_NAME, true);
  };

  const countryConfig = COUNTRY_CONFIGS[detectedCountry];

  return (
    <div ref={ref}>
      {!shouldShow ? null : (
        <div
          css={{
            borderTop: '1px solid rgba(0,0,0,0.2)',
            paddingTop: unit(3),
            paddingBottom: unit(3),
            backgroundColor: disabledColor(2),
            position: 'relative',
            boxShadow:
              '0 0.5px 0 0 rgba(0, 0, 0, 0.1), 0 0 64px 0 rgba(0, 0, 0, 0.05)',
            zIndex: 2002,
          }}
        >
          <ContentContainer>
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div
                css={{
                  flex: 1,
                }}
              >
                <div css={{ flex: 1, marginRight: unit(2), fontSize: 16 }}>
                  <h2
                    css={{
                      margin: 0,
                      fontSize: 16,
                      fontWeight: 600,
                      marginBottom: unit(0.5),
                    }}
                  >
                    {countryConfig.heading}
                  </h2>
                </div>
                <a
                  onClick={_handleLinkClick}
                  href={countryConfig.link}
                  css={{
                    fontSize: 14,
                    display: 'flex',
                    alignItems: 'center',
                    color: errorColor(3),
                  }}
                >
                  {countryConfig.linkText}
                </a>
              </div>
              <div
                tabIndex="0"
                role="button"
                aria-pressed="false"
                onClick={_hide}
                onKeyPress={_hide}
                css={{
                  marginLeft: unit(6),
                  width: 18,
                  height: 18,
                  cursor: 'pointer',
                }}
              >
                <CrossIcon
                  focus={{
                    background: disabledColor(10),
                    iconColor: disabledColor(4),
                  }}
                  iconColor={disabledColor(10)}
                  background={disabledColor(4)}
                />
              </div>
            </div>
          </ContentContainer>
        </div>
      )}
    </div>
  );
});

export default MismatchedCountryBanner;
