import React from 'react';

import styled from '@emotion/styled';

interface ChevronDownProps {
  size?: number;
  className?: string;
}

const ChevronDown = ({
  size = 16,
  className,
}: ChevronDownProps): JSX.Element => (
  <svg
    viewBox="0 0 18 18"
    width={size}
    height={size}
    className={className}
    css={{
      display: 'inline-block',
      verticalAlign: 'middle',
    }}
  >
    <path
      fill="currentColor"
      d="m12.5714286 5.96078644 1.4285714 1.41421356-4.44444444 4.3997755c-.30682487.3037411-.80428625.3037411-1.11111112 0l-4.44444444-4.3997755 1.42857143-1.41421356 3.57142857 3.5355339z"
      fillRule="evenodd"
    />
  </svg>
);

export default styled(ChevronDown)({ transition: 'fill 200ms ease-in-out' });
