/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type MenuSections_sections = ReadonlyArray<{
    readonly __typename: "MarketingProductWebsiteNavigationSection";
    readonly productCategory: {
        readonly name: string;
        readonly products: ReadonlyArray<{
            readonly name: string;
            readonly code: string;
            readonly isComingSoon: boolean;
        }>;
    } | null;
    readonly " $refType": "MenuSections_sections";
} | {
    readonly __typename: "SimpleWebsiteNavigationSection";
    readonly name: string;
    readonly links: ReadonlyArray<{
        readonly title: string;
        readonly href: string;
        readonly label: string;
    }>;
    readonly " $refType": "MenuSections_sections";
} | {
    /*This will never be '%other', but we need some
    value in case none of the concrete values match.*/
    readonly __typename: "%other";
    readonly " $refType": "MenuSections_sections";
}>;
export type MenuSections_sections$data = MenuSections_sections;
export type MenuSections_sections$key = ReadonlyArray<{
    readonly " $data"?: MenuSections_sections$data;
    readonly " $fragmentRefs": FragmentRefs<"MenuSections_sections">;
}>;



const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "MenuSections_sections",
  "type": "WebsiteNavigationSection",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "__typename",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "type": "MarketingProductWebsiteNavigationSection",
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "productCategory",
          "storageKey": null,
          "args": null,
          "concreteType": "WebsiteMarketingProductCategory",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "products",
              "storageKey": null,
              "args": null,
              "concreteType": "WebsiteMarketingProduct",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "code",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "isComingSoon",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "InlineFragment",
      "type": "SimpleWebsiteNavigationSection",
      "selections": [
        (v0/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "links",
          "storageKey": null,
          "args": null,
          "concreteType": "SimpleWebsiteNavigationLink",
          "plural": true,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "title",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "href",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "label",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
})();
(node as any).hash = '137fe0aa3e888b78b8dde14e47a26ecf';
export default node;
