import React, { useRef, useState } from 'react';

import useRect from '@zego/hooks/useRect';
import { disabledColor, unit } from '@zego/theme';

import SectionHeader from '@zego/components/footer/SectionHeader';

import chevronDown from '@zego/static/images/icons/chevron-down.svg';

export default function MenuDropdown({ heading, children }) {
  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef();
  const rect = useRect(ref);

  return (
    <div
      css={{
        borderBottom: `1px solid ${disabledColor(4)}`,
      }}
    >
      <SectionHeader
        onClick={() => setIsOpen(isOpen => !isOpen)}
        css={{
          position: 'relative',
          paddingTop: unit(3),
          cursor: 'default',
          fontSize: '18px',
          ':after': {
            display: 'block',
            position: 'absolute',
            content: '""',
            backgroundImage: `url(${chevronDown})`,
            transition: 'transform 400ms ease-in-out',
            width: 16,
            height: 16,
            top: '50%',
            right: 0,
            marginTop: -8,
            transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
          },
        }}
      >
        {heading}
      </SectionHeader>
      <div
        css={{
          overflowY: 'hidden',
          height: 0,
          transition: rect ? `height 400ms ease-in-out` : 'none',
        }}
        style={isOpen ? { height: rect.height } : {}}
      >
        <div ref={ref} css={{ paddingBottom: unit(2) }}>
          {children}
        </div>
      </div>
    </div>
  );
}
