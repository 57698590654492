/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Header_viewer = {
    readonly countryByCode: {
        readonly websiteMarketingProductCategories: ReadonlyArray<{
            readonly " $fragmentRefs": FragmentRefs<"DesktopHeader_categories">;
        }>;
        readonly websiteNavigationSections: ReadonlyArray<{
            readonly " $fragmentRefs": FragmentRefs<"MenuSections_sections">;
        }>;
    } | null;
    readonly " $refType": "Header_viewer";
};
export type Header_viewer$data = Header_viewer;
export type Header_viewer$key = {
    readonly " $data"?: Header_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"Header_viewer">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "Header_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "country",
      "type": "String!",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "countryByCode",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "code",
          "variableName": "country"
        }
      ],
      "concreteType": "Country",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "websiteMarketingProductCategories",
          "storageKey": null,
          "args": null,
          "concreteType": "WebsiteMarketingProductCategory",
          "plural": true,
          "selections": [
            {
              "kind": "FragmentSpread",
              "name": "DesktopHeader_categories",
              "args": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "websiteNavigationSections",
          "storageKey": null,
          "args": null,
          "concreteType": null,
          "plural": true,
          "selections": [
            {
              "kind": "FragmentSpread",
              "name": "MenuSections_sections",
              "args": null
            }
          ]
        }
      ]
    }
  ]
};
(node as any).hash = '289ec1ee87239bcdd700ffd939fcf350';
export default node;
