import React, { useEffect } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import useSiftDataLayer from '@zego/hooks/useSiftDataLayer';
import useSession from '@zego/session/useSession';

import CoreLayout from './CoreLayout';

/**
 * This ensures we fetch the session token on every page load, and update the
 * auth token cookie.
 *
 * If a mutation does something that authenticates a user, just include
 * the SessionLayout_viewer fragment and as long as this component is mounted,
 * everything session related will be handled auto-magically as a side-effect.
 */
const SessionLayout = ({ viewer, children, ...props }) => {
  const updateSession = useSession();

  const customerNumber = viewer.currentUser
    ? viewer.currentUser.customerNumber
    : null;

  const { id, token, expiresAt } = viewer.session;

  useEffect(() => {
    updateSession({ id, token, expiresAt }, customerNumber);
  }, [updateSession, viewer.session, customerNumber, id, token, expiresAt]);

  useSiftDataLayer({
    sessionId: viewer.session.id || '',
    customerNumber: customerNumber || '',
  });

  return <CoreLayout {...props}>{children}</CoreLayout>;
};

export default createFragmentContainer(SessionLayout, {
  viewer: graphql`
    fragment SessionLayout_viewer on Viewer {
      session {
        id # This shouldn't change for a single user session
        token
        expiresAt
      }
      currentUser {
        fullName # TODO: Figure out how to remove this, Needed by scooter QE right now
        customerNumber
      }
    }
  `,
});
