/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ProductTheme_product = {
    readonly color: string;
    readonly " $refType": "ProductTheme_product";
};
export type ProductTheme_product$data = ProductTheme_product;
export type ProductTheme_product$key = {
    readonly " $data"?: ProductTheme_product$data;
    readonly " $fragmentRefs": FragmentRefs<"ProductTheme_product">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "ProductTheme_product",
  "type": "WebsiteMarketingProduct",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "color",
      "args": null,
      "storageKey": null
    }
  ]
};
(node as any).hash = 'bca40cb64104a641a3dcf4de1023bf5b';
export default node;
