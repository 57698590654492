import styled from '@emotion/styled';

import { black } from '@zego/theme';

import { default as BaseLink } from '@zego/components/Link';

const FooterLink = styled(BaseLink)({
  textDecoration: 'none',
  fontWeight: 500,
  color: black,
  fontSize: 14,
  opacity: 0.4,
  ':hover, :focus': {
    textDecoration: 'underline',
  },
});

export default FooterLink;
