import React from 'react';

import { css } from '@emotion/core';

const UnstyledButton = React.forwardRef(
  (
    { disabled, onClick = () => {}, tabIndex = 0, children, className },
    ref,
  ) => (
    <span
      ref={ref}
      role="button"
      tabIndex={tabIndex}
      aria-disabled={disabled}
      onClick={e => (disabled ? e.preventDefault : onClick(e))}
      onKeyDown={e => {
        e.preventDefault();
        if (!disabled && (e.keyCode === 32 || e.keyCode === 13)) {
          onClick(e);
        }
      }}
      css={css`
        cursor: default;
      `}
      className={className}
    >
      {children}
    </span>
  ),
);

export default UnstyledButton;
