/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DesktopSubmenu_products = ReadonlyArray<{
    readonly name: string;
    readonly isComingSoon: boolean;
    readonly " $fragmentRefs": FragmentRefs<"DesktopSubmenuProduct_product" | "DesktopSubmenuPlaceholder_product">;
    readonly " $refType": "DesktopSubmenu_products";
}>;
export type DesktopSubmenu_products$data = DesktopSubmenu_products;
export type DesktopSubmenu_products$key = ReadonlyArray<{
    readonly " $data"?: DesktopSubmenu_products$data;
    readonly " $fragmentRefs": FragmentRefs<"DesktopSubmenu_products">;
}>;



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "DesktopSubmenu_products",
  "type": "WebsiteMarketingProduct",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isComingSoon",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "DesktopSubmenuProduct_product",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "DesktopSubmenuPlaceholder_product",
      "args": null
    }
  ]
};
(node as any).hash = '9fcba9d0a9d2012fc999758561243829';
export default node;
