/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type BaseLayout_viewer = {
    readonly " $fragmentRefs": FragmentRefs<"SessionLayout_viewer" | "Header_viewer" | "Footer_viewer">;
    readonly " $refType": "BaseLayout_viewer";
};
export type BaseLayout_viewer$data = BaseLayout_viewer;
export type BaseLayout_viewer$key = {
    readonly " $data"?: BaseLayout_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"BaseLayout_viewer">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "country",
    "variableName": "country"
  }
];
return {
  "kind": "Fragment",
  "name": "BaseLayout_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "country",
      "type": "String!",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "SessionLayout_viewer",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "Header_viewer",
      "args": (v0/*: any*/)
    },
    {
      "kind": "FragmentSpread",
      "name": "Footer_viewer",
      "args": (v0/*: any*/)
    }
  ]
};
})();
(node as any).hash = '837131755366e6758422fad1f0726143';
export default node;
