import React from 'react';

import { disabledColor, unit } from '@zego/theme';

import SectionHeader from '@zego/components/footer/SectionHeader';
import Link from '@zego/components/Link';

const MenuRow = ({ heading, icon, link }) => (
  <div
    css={{
      borderBottom: `1px solid ${disabledColor(4)}`,
    }}
  >
    <Link href={link}>
      <SectionHeader
        css={{
          position: 'relative',
          paddingTop: unit(3),
          cursor: 'default',
          fontSize: '18px',
          display: 'flex',
          alignItems: 'flex-start',
        }}
      >
        {icon && <img src={icon} css={{ paddingRight: unit(1) }} alt="" />}
        {heading}
      </SectionHeader>
    </Link>
  </div>
);

export default MenuRow;
