import React, { useContext } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Trans } from '@lingui/macro';

import PageContext from '@zego/context/PageContext';
import { black, disabledColor, unit, white } from '@zego/theme';
import { pathWithCountry } from '@zego/utils/route.ts';

import BaseRegularText from '@zego/components/atoms/RegularText';
import Spacer from '@zego/components/atoms/Spacer';
import ContentContainer from '@zego/components/ContentContainer';
import MenuSections from '@zego/components/MenuSections';
import ZegoCopyright from '@zego/components/ZegoCopyright';

import FooterCountryPicker from './FooterCountryPicker';
import FooterLink from './FooterLink';

import Logo from '@zego/static/svgs/ZegoLogoGreen.svg';

const InlineSectionLink = styled(FooterLink)({
  display: 'inline-block',
  margin: '0 8px',
});

const FooterContainer = ContentContainer.withComponent('footer');

const SmallPrint = styled(BaseRegularText)({
  display: 'block',
  fontSize: 12,
  lineHeight: '14px',
  color: black,
  opacity: 0.4,
}).withComponent('small');

const Footer = ({ viewer }) => {
  const { country } = useContext(PageContext);
  return (
    <FooterContainer
      css={{
        paddingTop: unit(4),
        paddingBottom: unit(8),
        backgroundColor: white,
      }}
    >
      <div
        css={css`
          display: grid;
          grid-column-gap: 20px;
          grid-row-gap: ${unit(4)}px;
          grid-auto-rows: auto;
          grid-template-columns: [aside-start main-start] 1fr [aside-end main-end];
          @media screen and (min-width: 768px) {
            grid-template-columns: [aside-start] 2fr [aside-end main-start] 10fr [main-end];
          }
        `}
      >
        <div
          css={css`
            grid-column: aside;
            display: flex;
            align-items: center;
            justify-content: center;

            @media screen and (min-width: 768px) {
              align-items: start;
              justify-content: start;
            }
          `}
        >
          <img alt="" src={Logo} css={{ width: 60 }} />
        </div>

        <div
          css={css`
            @media screen and (min-width: 768px) {
              display: none;
            }
          `}
        >
          <MenuSections
            as="mobile"
            sections={viewer.countryByCode.websiteNavigationSections}
            user={viewer.currentUser}
          />
        </div>

        <div
          css={css`
            grid-column: main;
            display: none;
            @media screen and (min-width: 768px) {
              margin: -${unit(1)}px;
              display: flex;
            }
          `}
        >
          <MenuSections
            as="desktop"
            sections={viewer.countryByCode.websiteNavigationSections}
            user={viewer.currentUser}
          />
        </div>

        <div
          css={css`
            grid-column: aside;
            align-items: start;
            justify-content: start;
            @media screen and (min-width: 768px) {
              margin-top: ${unit(8)}px;
            }
          `}
        >
          <FooterCountryPicker viewer={viewer} currentCountry={country} />
        </div>

        <div
          css={css`
            grid-column: main;
            display: flex;
            flex-wrap: wrap;
            @media screen and (min-width: 768px) {
              margin-top: ${unit(8)}px;
            }
          `}
        >
          <div
            css={css`
              margin: 0 -8px;
            `}
          >
            {country !== 'es' ? (
              <InlineSectionLink
                href="https://www.facebook.com/zegocoverinsurance/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook
              </InlineSectionLink>
            ) : null}
            <InlineSectionLink
              href={
                country === 'es'
                  ? 'https://twitter.com/Zego_es/'
                  : 'https://twitter.com/zegocover/'
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              Twitter
            </InlineSectionLink>
            {country !== 'es' ? (
              <InlineSectionLink
                href="https://www.instagram.com/zego/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </InlineSectionLink>
            ) : null}
          </div>
          <div
            css={css`
              padding: 0 ${unit(2)}px;
            `}
          >
            •
          </div>
          <div
            css={css`
              margin: 0 -8px;
            `}
          >
            <InlineSectionLink href={pathWithCountry(country, '/privacy')}>
              <Trans id="Website Footer / Privacy link">Privacy</Trans>
            </InlineSectionLink>
            <InlineSectionLink
              href={pathWithCountry(country, '/terms-of-business')}
            >
              <Trans id="Website Footer / Terms of business link">
                Terms of business
              </Trans>
            </InlineSectionLink>
            <InlineSectionLink href={pathWithCountry(country, '/cookies')}>
              <Trans id="Website Footer / Cookies link">Cookies</Trans>
            </InlineSectionLink>
            <InlineSectionLink href="/security">
              <Trans id="Website Footer / Security link">Security</Trans>
            </InlineSectionLink>
          </div>
        </div>

        <div
          css={css`
            grid-column: aside;
          `}
        >
          <SmallPrint css={{ opacity: 1, color: disabledColor(10) }}>
            <ZegoCopyright />
          </SmallPrint>
        </div>

        {country !== 'ie' ? (
          <div
            css={css`
                grid-column: main;
              `}
          >
            <SmallPrint>
              <Trans id="Website Footer / FCA Notice">
                Zego is a trading name of Extracover Limited, which is
                authorised and regulated by the Financial Conduct Authority.
              </Trans>
            </SmallPrint>
            {country === 'gb' && <SmallPrint>(FRN: 757871).</SmallPrint>}
            <Spacer height={unit(1.5)} />
            <SmallPrint>
              <Trans id="Website Footer / Registered address">
                ExtraCover Limited is registered in England and Wales, No
                10128841. Registered address: 7th Floor Exchange House, 12
                Primrose Street, London, England, EC2A 2BQ.
              </Trans>
            </SmallPrint>
          </div>
        ) : (
          <div
            css={css`
                grid-column: main;
              `}
          >
            <SmallPrint>
              <Trans id="Website Footer / FCA Notice / IE">
                Zego is a trading name of Zego BV, which is authorised and
                regulated by the Dutch Authority for Financial Markets (AFM)
                registration number 12047576 with a branch office in Ireland.
              </Trans>
            </SmallPrint>
            <Spacer height={unit(1.5)} />
            <SmallPrint>
              <Trans id="Website Footer / Registered address / IE">
                Registered address: 38 Upper Mount Street, Dublin 2
              </Trans>
            </SmallPrint>
          </div>
        )}
      </div>
    </FooterContainer>
  );
};

export default createFragmentContainer(Footer, {
  viewer: graphql`
    fragment Footer_viewer on Viewer
      @argumentDefinitions(country: { type: "String!" }) {
      ...FooterCountryPicker_viewer
      countryByCode(code: $country) {
        websiteNavigationSections {
          ...MenuSections_sections
        }
      }
    }
  `,
});
