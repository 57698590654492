import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import { ThemeProvider } from 'emotion-theming';

const ProductTheme = ({ product, children }) => (
  <ThemeProvider
    theme={{
      tintColor: product.color,
    }}
  >
    {children}
  </ThemeProvider>
);

export default createFragmentContainer(ProductTheme, {
  product: graphql`
    fragment ProductTheme_product on WebsiteMarketingProduct {
      color
    }
  `,
});
