import React, { useRef } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import Headroom from 'react-headroom';

import useRect from '@zego/hooks/useRect';

import DesktopHeader from './DesktopHeader';
import MismatchedCountryBanner from './MismatchedCountryBanner';
import MobileHeader from './MobileHeader';

const Header = props => {
  const ref = useRef();
  const rect = useRect(ref);

  return (
    <>
      <MismatchedCountryBanner
        ref={ref}
        height={rect ? rect.height || 0 : 0}
      />
      <Headroom
        pinStart={rect ? rect.height || 0 : 0}
        css={{
          position: 'relative',
          zIndex: 1001,
          '.no-touch &:hover, .headroom--pinned': {
            boxShadow: '0 0 64px 0 rgba(0, 0, 0, 0.05)',
          },
        }}
      >
        <div
          css={{
            display: 'block',
            '@media screen and (min-width: 768px)': { display: 'none' },
          }}
        >
          <MobileHeader
            {...props}
            sections={props.viewer.countryByCode.websiteNavigationSections}
            user={props.viewer.currentUser}
          />
        </div>
        <div
          css={{
            display: 'none',
            '@media screen and (min-width: 768px)': { display: 'block' },
          }}
        >
          <DesktopHeader
            {...props}
            categories={
              props.viewer.countryByCode.websiteMarketingProductCategories
            }
            user={props.viewer.currentUser}
          />
        </div>
      </Headroom>
    </>
  );
};
export default createFragmentContainer(Header, {
  viewer: graphql`
    fragment Header_viewer on Viewer
      @argumentDefinitions(country: { type: "String!" }) {
      countryByCode(code: $country) {
        websiteMarketingProductCategories {
          ...DesktopHeader_categories
        }
        websiteNavigationSections {
          ...MenuSections_sections
        }
      }
    }
  `,
});
