import React, { useContext, useState } from 'react';

import styled from '@emotion/styled';

import PageContext from '@zego/context/PageContext';
import { disabledColor, primaryColor, unit, white } from '@zego/theme';
import { pathWithCountry } from '@zego/utils/route.ts';

import ContentContainer from '@zego/components/ContentContainer';
import MobileModal from '@zego/components/MobileModal';

import Logo from './Logo';
import MenuIcon from './MenuIcon';
import MobileSubmenu from './MobileSubmenu';

const HeaderContainer = styled(ContentContainer)({
  height: 100,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingRight: 30 - unit(1.5),
}).withComponent('nav');

const Backdrop = styled('div')({
  position: 'absolute',
  height: 100,
  backgroundColor: white,
  pointerEvents: 'none',
  left: 0,
  right: 0,
  top: 0,
  transition: 'all 200ms ease-in-out',
  zIndex: -1,
  opacity: 0,
  boxShadow: 'none',
  transform: 'translateY(-10%)',
});

const MenuButton = styled('button')({
  cursor: 'pointer',
  background: 'none',
  border: 0,
  padding: 0,
  margin: 0,
});

const MenuButtonWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: unit(1.5),
});

const HeaderWrapper = styled('header')(
  {
    height: 100,
    '.no-touch &:hover, .headroom--pinned &': {
      [Backdrop]: {
        opacity: 1,
        transform: 'translateY(0)',
        boxShadow: '0 0.5px 0 0 rgba(0, 0, 0, 0.15)',
      },
    },
  },
  ({ isInitiallyLightOnDark }) =>
    isInitiallyLightOnDark
      ? {
          '.headroom--unfixed &': {
            [Logo]: {
              fill: white,
            },
            [MenuIcon]: {
              color: white,
            },
            '.no-touch &:hover': {
              [Logo]: {
                fill: primaryColor(11),
              },
              [MenuIcon]: {
                color: disabledColor(10),
              },
            },
          },
        }
      : {},
);

const MobileHeader = ({
  isInitiallyLightOnDark = true,
  sections,
  showLogoutCTA,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMobileOpen = () => setMenuOpen(true);
  const handleMobileClose = () => setMenuOpen(false);
  const { country } = useContext(PageContext);

  return (
    <HeaderWrapper
      isInitiallyLightOnDark={isInitiallyLightOnDark}
      role="banner"
    >
      <HeaderContainer role="navigation">
        <a
          href={pathWithCountry(country, '/')}
          css={{
            flexGrow: 0,
            display: 'flex',
            paddingTop: unit(5),
            paddingBottom: unit(5),
          }}
          aria-label="Zego Homepage"
        >
          <Logo width={91} css={{ fill: primaryColor(11) }} />
        </a>
        <div css={{ flex: 1 }} />
        <MenuButton onClick={handleMobileOpen} aria-label="Open menu">
          <MenuButtonWrapper>
            <MenuIcon css={{ color: disabledColor(10) }} />
          </MenuButtonWrapper>
        </MenuButton>
      </HeaderContainer>
      <Backdrop />
      <MobileModal
        isOpen={menuOpen}
        onRequestClose={handleMobileClose}
        contentLabel="Mobile Menu"
      >
        <MobileSubmenu
          isMobileHeader={true}
          sections={sections}
          showLogoutCTA={showLogoutCTA}
        />
      </MobileModal>
    </HeaderWrapper>
  );
};

export default MobileHeader;
