// TODO: If you make edits to this file you must deal with these ESLint rules
/*eslint-disable  @typescript-eslint/no-use-before-define */

import React, { useContext } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import styled from '@emotion/styled';
import { Trans, t } from '@lingui/macro';

import { useFlags } from 'launchdarkly-react-client-sdk';

import PageContext from '@zego/context/PageContext';
import useI18n from '@zego/i18n/useI18n';
import { disabledColor, errorColor, unit } from '@zego/theme';
import { useTracking } from '@zego/tracking';
import {
  getCountrySupportLink,
  landingPagePathFromProductCode,
  pathWithCountry,
} from '@zego/utils/route.ts';

import FooterLink from '@zego/components/footer/FooterLink';
import FooterSection from '@zego/components/footer/FooterSection';
import MenuDropdown from '@zego/components/MenuDropdown';
import MenuRow from '@zego/components/MenuRow';
import LogoutCTA from '@zego/components/molecules/LogoutCTA';
import withFeatureFlags from '@zego/components/withFeatureFlags';

import QuestionMarkIcon from '@zego/static/svgs/QuestionMark.svg';

const MOBILE_FOOTER_SUPPORT_COUNTRIES_ALLOWED = ['gb', 'ie', 'fr', 'es', 'nl'];
const MOBILE_FOOTER_MY_ACCOUNT_COUNTRIES_ALLOWED = ['gb', 'ie'];

const SectionLink = styled(FooterLink)({
  display: 'block',
  paddingTop: unit(1),
  paddingBottom: unit(1),
  position: 'relative',
  fontSize: '18px',
});

const ProductComingSoon = styled(SectionLink)(
  {
    display: 'flex',
    opacity: 1,
    ':hover': {
      textDecoration: 'none',
    },
    '> span': {
      flex: 1,
      opacity: 0.1,
    },
  },
  props => ({
    ':after': {
      fontWeight: 600,
      color: errorColor(3),
      textTransform: 'uppercase',
      content: `${props.i18n._(t('Footer Section / Coming Soon')`"Soon"`)}`,
    },
    '@media screen and (min-width: 768px)': {
      display: 'block',
      '> span': {
        opacity: 0.1,
        flex: 0,
      },
      ':after': {
        fontWeight: 300,
        color: disabledColor(4),
        content: '""',
      },
    },
  }),
).withComponent('span');

const ProductSectionLink = SectionLink.withComponent('a');

const MenuSections = ({
  as = 'desktop',
  sections,
  showLogoutCTA = false,
  isMobileHeader,
}) => {
  const { myAccountEnabled, myZegoAccountLoginPath } = useFlags();
  const { i18n } = useI18n();
  const MenuSection = as === 'desktop' ? FooterSection : MenuDropdown;
  const trackingMenuType = isMobileHeader ? 'TopNav' : 'Footer';
  const categories = sections.filter(
    s => s.__typename === 'MarketingProductWebsiteNavigationSection',
  );

  const simpleSection = sections.filter(
    s => s.__typename === 'SimpleWebsiteNavigationSection',
  );
  const { country } = useContext(PageContext);
  const { trackEvent } = useTracking();

  return (
    <>
      {categories.map(category => (
        <MenuSection
          key={category.productCategory.name}
          heading={`For ${category.productCategory.name}`}
        >
          {category.productCategory.products.map(product =>
            product.isComingSoon ? (
              <ProductComingSoon key={product.code} i18n={i18n}>
                <span>{product.name}</span>
              </ProductComingSoon>
            ) : (
              <ProductSectionLink
                onClick={() => {
                  trackEvent(`${product.name} - ${trackingMenuType}`);
                }}
                key={product.code}
                href={pathWithCountry(
                  country,
                  landingPagePathFromProductCode(product.code),
                )}
              >
                {product.name}
              </ProductSectionLink>
            ),
          )}
        </MenuSection>
      ))}

      {simpleSection.map(section => (
        <React.Fragment key={section.name}>
          {!isMobileHeader ? (
            <MenuSection heading={section.name}>
              {section.links.map(link => (
                <SectionLink key={link.label} href={link.href} external={true}>
                  {link.label}
                </SectionLink>
              ))}
            </MenuSection>
          ) : null}
        </React.Fragment>
      ))}
      {as === 'mobile' ? (
        <>
          {country === 'gb' && isMobileHeader && (
            <MenuRow heading="Brokers" link="/broker/" />
          )}
          {MOBILE_FOOTER_SUPPORT_COUNTRIES_ALLOWED.includes(country) && (
            <>
              <MenuRow
                icon={QuestionMarkIcon}
                heading={
                  <Trans id="Website Header / Contact us">Support</Trans>
                }
                link={getCountrySupportLink(country)}
              />
            </>
          )}
          {MOBILE_FOOTER_MY_ACCOUNT_COUNTRIES_ALLOWED.includes(country) && (
            <AuthButton
              enabled={myAccountEnabled}
              myZegoEnabled={myZegoAccountLoginPath}
              as={as}
              showLogoutCTA={showLogoutCTA}
            />
          )}
        </>
      ) : null}
    </>
  );
};

const AuthButton = ({ enabled, myZegoEnabled, as, showLogoutCTA }) => {
  const accountUrl = myZegoEnabled ? '/my-zego/' : '/my-account/';
  if (!enabled) {
    return null;
  }
  return showLogoutCTA ? (
    <LogoutCTA as={as} />
  ) : (
    <MenuRow link={accountUrl} heading="My account" />
  );
};

export default createFragmentContainer(withFeatureFlags(MenuSections), {
  sections: graphql`
    fragment MenuSections_sections on WebsiteNavigationSection
      @relay(plural: true) {
      __typename
      ... on MarketingProductWebsiteNavigationSection {
        productCategory {
          name
          products {
            name
            code
            isComingSoon
          }
        }
      }
      ... on SimpleWebsiteNavigationSection {
        name
        links {
          title
          href
          label
        }
      }
    }
  `,
});
