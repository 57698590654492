import React from 'react';

import styled from '@emotion/styled';

const MenuIcon = ({ className }) => (
  <svg
    width="20px"
    height="16px"
    viewBox="0 0 20 16"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fill="currentColor"
      d="M0 14h20v2H0v-2zm0-7h17v2H0V7zm0-7h20v2H0V0z"
    />
  </svg>
);

export default styled(MenuIcon)({ transition: 'fill 200ms ease-in-out' });
